
import { defineComponent, PropType, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';
import { useTranslations } from '@/shared/composables/useTranslations';
import { Product } from '@/shared/interfaces/product';
import ProductCardWrapper from '@/shared/components/ProductCard/ProductCardWrapper.vue';

export default defineComponent({
  name: 'AttachmentsBlock',
  components: {
    Swiper,
    SwiperSlide,
    ProductCardWrapper,
  },
  props: {
    attachments: { type: Object as PropType<Product[]>, required: true },
    selected: { type: Object as PropType<Product[]>, required: true },
  },
  emits: ['attachmentSelect', 'attachmentDeselect'],
  setup(props, { emit }) {
    const { t } = useTranslations();
    const swiper = ref<unknown>(null);

    const swiperOptions = {
      0: { slidesPerView: 2, spaceBetween: 12, allowTouchMove: false },
      1024: { slidesPerView: 4, spaceBetween: 24, allowTouchMove: false },
    };

    const onSwiper = (swr: unknown) => {
      swiper.value = swr;
    };

    const productSelect = (attachment: Product) => emit('attachmentSelect', attachment);
    const productDeselect = (id: number) => emit('attachmentDeselect', id);
    const isSelected = (id: number) => !!props.selected.find((item) => item.id === id);
    return {
      t,
      swiper,
      swiperOptions,
      onSwiper,
      isSelected,
      productSelect,
      productDeselect,
    };
  },
});
