import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88b3d70a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-gray-200" }
const _hoisted_2 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ProductCardWrapper = _resolveComponent("ProductCardWrapper")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseHeading, {
            size: 6,
            class: "title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_request_attachments_title')), 1)
            ]),
            _: 1
          }),
          (_ctx.swiper)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["flex items-start", [
            { 'hidden-controls': _ctx.attachments.length < 5 },
            { 'hidden-controls-mobile': _ctx.attachments.length < 3 },
          ]])
              }, [
                _createVNode(_component_BaseButton, {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.swiper.slidePrev()), ["prevent"])),
                  class: "mr-2",
                  variant: "text",
                  size: 0,
                  disabled: _ctx.swiper.isBeginning
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseIcon, {
                      icon: "next",
                      class: "w-4 lg:w-6 rotate-180"
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_BaseButton, {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.swiper.slideNext()), ["prevent"])),
                  variant: "text",
                  size: 0,
                  disabled: _ctx.swiper.isEnd
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseIcon, {
                      icon: "next",
                      class: "w-4 lg:w-6"
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Swiper, {
          breakpoints: _ctx.swiperOptions,
          onSwiper: _ctx.onSwiper,
          class: "w-full"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (product) => {
              return (_openBlock(), _createBlock(_component_SwiperSlide, {
                key: product.id,
                class: "p-0.5 w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductCardWrapper, {
                    class: "w-full mb-3",
                    product: product,
                    isClicked: _ctx.isSelected(product.id),
                    isSmall: "",
                    isDark: "",
                    onProductSelect: _ctx.productSelect,
                    onProductDeselect: _ctx.productDeselect
                  }, null, 8, ["product", "isClicked", "onProductSelect", "onProductDeselect"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["breakpoints", "onSwiper"])
      ]),
      _: 1
    })
  ]))
}