
import { defineComponent, PropType, computed } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import ProductCardPriceList from './ProductCardPriceList.vue';

export default defineComponent({
  name: 'ProductCard',
  components: {
    ProductCardPriceList,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
    isSmall: { type: Boolean, default: false },
    isDark: { type: Boolean, default: false },
  },
  setup(props) {
    const { t } = useTranslations();
    const { getFallbackImage } = useFallbackImage();
    const { isLm } = useVersions();

    const smallTextClass = computed(() => (props.isSmall ? 'small-text' : ''));

    return { t, smallTextClass, getFallbackImage, isLm };
  },
});
