import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-daec7786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col relative h-full justify-between max-w-full" }
const _hoisted_2 = { class: "flex flex-col text-left max-w-full" }
const _hoisted_3 = {
  key: 1,
  class: "card-new-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_ProductCardPriceList = _resolveComponent("ProductCardPriceList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["image-container", {
          'rounded-tl-4': _ctx.product.newProduct,
          'image-dark': _ctx.isDark,
        }])
      }, [
        (_ctx.product.imageUrl)
          ? (_openBlock(), _createBlock(_component_ProductImage, {
              key: 0,
              src: _ctx.product.imageUrl,
              alt: "Product",
              class: "product-image"
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              icon: _ctx.getFallbackImage(_ctx.product),
              alt: "Product",
              class: "text-gray-1700 product-image"
            }, null, 8, ["icon"]))
      ], 2),
      _createVNode(_component_BaseText, {
        variant: "16-17",
        class: _normalizeClass(["py-2", { 'small-id': _ctx.isSmall }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.product.id), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_BaseText, {
        variant: "18-20",
        class: _normalizeClass(["font-bold break-words", _ctx.smallTextClass])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.product.name), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_BaseText, {
        variant: "18-20",
        class: _normalizeClass(["font-bold break-words", _ctx.smallTextClass])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.product.model), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    (!_ctx.isLm)
      ? (_openBlock(), _createBlock(_component_ProductCardPriceList, {
          key: 0,
          product: _ctx.product,
          isSmall: _ctx.isSmall
        }, null, 8, ["product", "isSmall"]))
      : _createCommentVNode("", true),
    (_ctx.product.newProduct)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t('product_card_new_label')), 1))
      : _createCommentVNode("", true)
  ]))
}