
import { defineComponent, PropType } from 'vue';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import ProductCard from './ProductCard.vue';

export default defineComponent({
  name: 'ProductCardWrapper',
  components: {
    ProductCard,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
    isClicked: { type: Boolean, default: false },
  },
  emits: ['productSelect', 'productDeselect'],
  setup(props, { emit }) {
    const productClick = () => {
      if (props.isClicked) emit('productDeselect', props.product.id);
      else emit('productSelect', props.product);
    };
    return { productClick };
  },
});
