import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05d73d9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, { opened: _ctx.opened }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        size: 6,
        class: "pb-3 lg:pb-5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('add_to_cart_success_title')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: _ctx.routes.productsList.path,
          custom: ""
        }, {
          default: _withCtx(({ navigate }) => [
            _createVNode(_component_BaseButton, {
              class: "button mb-1 lg:mb-0 lg:mr-2",
              size: 2,
              variant: "secondary",
              onClick: ($event: any) => {
            _ctx.$emit('close');
            navigate();
          }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('add_to_cart_success_back')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, {
          to: _ctx.routes.cart.path,
          custom: ""
        }, {
          default: _withCtx(({ navigate }) => [
            _createVNode(_component_BaseButton, {
              class: "button",
              size: 2,
              variant: "primary",
              onClick: ($event: any) => {
            _ctx.$emit('close');
            navigate();
          }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('add_to_cart_success_next')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }, 8, ["opened"]))
}