
import { defineComponent, watch, ref } from 'vue';
import { useForm, useFieldArray } from 'vee-validate';
import { routes } from '@/shared/constants/routes';
import { useRouteParams } from '@/shared/composables/useRouteParams';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useOpenable } from '@/shared/composables/useOpenable';
import { PRODUCT_INITIAL_DATA } from './constants/productInitialData';
import { productValidationSchema } from './composables/productValidationSchema';
import { Product } from '@/shared/interfaces/product';
import ProductForm from './components/ProductForm.vue';
import AttachmentForm from './components/AttachmentForm.vue';
import AddToCartSuccessModal from './components/AddToCartSuccessModal.vue';
import AttachmentsBlock from './components/AttachmentsBlock.vue';
import { useProductDetails } from '@/pages/ProductDetails/composables/useProductDetails';
import { useProductAttachments } from '@/pages/ProductDetails/composables/useProductAttachments';
import { useSharedCart } from './composables/useCart';
import { CartProductWithId } from '@/shared/interfaces/cart';
import Error from '@/pages/Error.vue';

export default defineComponent({
  name: 'ProductAddToCart',
  components: {
    ProductForm,
    AttachmentForm,
    AddToCartSuccessModal,
    AttachmentsBlock,
    Error,
  },
  setup() {
    const { t } = useTranslations();
    const { id } = useRouteParams();
    const selectedAttachments = ref<Product[]>([]);
    const { isOpen: showModal, open: openModal, close: closeModal } = useOpenable();
    const { product, error } = useProductDetails(id);
    const { products: attachments, getAttachments } = useProductAttachments();
    const { addToCart } = useSharedCart();
    const { values, meta, errors, validate } = useForm({
      validationSchema: productValidationSchema(t),
      initialValues: PRODUCT_INITIAL_DATA,
      validateOnMount: false,
    });
    const { remove, push } = useFieldArray('attachments');

    const onSubmit = async () => {
      addToCart({ ...values, id } as CartProductWithId);
      openModal();
    };

    const attachmentSelect = (attachment: Product) => {
      selectedAttachments.value.push(attachment);
      push({
        range: values.range,
        id: attachment.id,
        sum: PRODUCT_INITIAL_DATA.sum,
      });
    };

    const attachmentDeselect = (attId: number) => {
      const index = values.attachments?.findIndex((item) => item.id === attId);
      if (index !== undefined) {
        remove(index);
        selectedAttachments.value.splice(index, 1);
      }
    };

    const attachmentPriceChange = (sum: number, index: number) => {
      if (values.attachments) values.attachments[index].sum = sum;
    };

    watch(product, (value) => {
      if (value) {
        getAttachments(value);
      }
    });

    watch(
      () => values.attachments,
      () => {
        validate();
      },
      { deep: true }
    );

    return {
      t,
      onSubmit,
      meta,
      values,
      errors,
      error,
      routes,
      product,
      closeModal,
      showModal,
      attachments,
      attachmentSelect,
      attachmentDeselect,
      selectedAttachments,
      attachmentPriceChange,
    };
  },
});
