import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1c4e7f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.pricing, (price, name) => {
      return (_openBlock(), _createElementBlock("div", {
        key: name,
        class: _normalizeClass(["card-pricing", { 'small-spacing': _ctx.isSmall }])
      }, [
        _createVNode(_component_BaseText, {
          class: _normalizeClass(["leading-none lg:text-18 2xl:text-20", _ctx.smallTextClass])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(`product_details_price_${name}`)), 1)
          ]),
          _: 2
        }, 1032, ["class"]),
        _createVNode(_component_BaseText, {
          class: _normalizeClass(["leading-none lg:text-18 2xl:text-20", _ctx.smallTextClass])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(price ? _ctx.priceString(price || 0) : '-'), 1)
          ]),
          _: 2
        }, 1032, ["class"])
      ], 2))
    }), 128))
  ]))
}