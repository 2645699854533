
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import { useNumbers } from '@/shared/composables/useNumbers';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import { useDropdownsOptions } from '@/shared/composables/useDropdownsOptions';
import { getPrice, getPriceWithIncurance } from '../utils/priceCalculation';
import { routes } from '@/shared/constants/routes';
import { ProductRequest } from '@/shared/interfaces/productRequest';
import { DateRange, DateStringsRange } from '@/shared/interfaces/calendar';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import { formatDate, calculateDaysDifference } from '@/shared/utils/formatDate';
import Calendar from '@/shared/components/Calendar/Calendar.vue';
import CalendarInputs from '@/pages/ProductAddToCart/components/CalendarInputs.vue';
import { initialRange } from '@/shared/constants/initialRange';

const defaultDeliveryOption = 'pickup';

export default defineComponent({
  name: 'AttachmentForm',
  components: {
    Calendar,
    CalendarInputs,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
    values: { type: Object as PropType<ProductRequest>, required: true },
    index: { type: Number, required: true },
    isError: { type: Boolean, default: false },
    modelValue: { type: Object as PropType<DateRange>, required: true },
  },
  emits: ['deselect', 'priceChange', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useTranslations();
    const range = ref<DateStringsRange>(initialRange);
    const { getFallbackImage } = useFallbackImage();
    const version = getConfig().appVersion;
    const { cPerDay, c } = useNumbers();
    const { deliveryOptions, availableBranches } = useDropdownsOptions();
    const isPickup = computed(() => props.values.type === defaultDeliveryOption);
    // TODO add quantity after BE changes
    const price = computed(() => getPrice(range.value, props.product.pricing));
    const isOpenEnded = computed(() => props.modelValue.end === t('calendar_open_ended'));
    const priceLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${formatDate(props.modelValue.start)}`
        : cPerDay(getPriceWithIncurance(price.value, Number(props.product?.insurance || 0)))
    );
    const sumLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${cPerDay(props.product.pricing.monthly)}`
        : c(props.values.attachments[props.index].sum)
    );

    const updateValues = (values: DateRange) => {
      emit('update:modelValue', values);
    };

    const updateDays = (value: DateStringsRange) => {
      range.value = value;
    };

    watch([price, range], ([newPrice, newRange]) => {
      emit(
        'priceChange',
        getPriceWithIncurance(newPrice, Number(props.product?.insurance || 0)) *
          calculateDaysDifference(newRange),
        props.index
      );
    });

    return {
      t,
      deliveryOptions,
      availableBranches,
      getFallbackImage,
      isPickup,
      range,
      price,
      cPerDay,
      c,
      routes,
      updateValues,
      updateDays,
      isOpenEnded,
      priceLabel,
      sumLabel,
      version,
      calculateDaysDifference,
    };
  },
});
