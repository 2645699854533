import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-673839bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-h-screen bg-white" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_ProductForm = _resolveComponent("ProductForm")!
  const _component_AttachmentForm = _resolveComponent("AttachmentForm")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_AttachmentsBlock = _resolveComponent("AttachmentsBlock")!
  const _component_AddToCartSuccessModal = _resolveComponent("AddToCartSuccessModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_Error, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BaseContainer, { class: "bg-white pt-3 lg:pt-4.5" }, {
              default: _withCtx(() => [
                _createVNode(_component_BackButton, { class: "back-button" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseHeading, {
                      mobileSmaller: "",
                      size: 4,
                      class: "ml-1 lg:ml-2.5 leading-none"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('base_back_button_text')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("form", {
                  onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                }, [
                  (_ctx.product)
                    ? (_openBlock(), _createBlock(_component_ProductForm, {
                        key: 0,
                        product: _ctx.product
                      }, null, 8, ["product"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedAttachments, (attachment, index) => {
                    return (_openBlock(), _createBlock(_component_AttachmentForm, {
                      key: attachment.id,
                      index: index,
                      product: attachment,
                      values: _ctx.values,
                      onDeselect: _ctx.attachmentDeselect,
                      onPriceChange: _ctx.attachmentPriceChange,
                      modelValue: _ctx.values.attachments[index].range,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.values.attachments[index].range) = $event)
                    }, null, 8, ["index", "product", "values", "onDeselect", "onPriceChange", "modelValue", "onUpdate:modelValue"]))
                  }), 128)),
                  _createVNode(_component_BaseText, { class: "disclaimer" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_request_disclaimer')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BaseButton, {
                    class: "submit-button",
                    type: "submit",
                    disabled: !_ctx.meta.valid
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_add_to_cart_btn')) + " ", 1),
                      _createVNode(_component_BaseIcon, {
                        icon: "arrow",
                        class: "button-icon"
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ], 32)
              ]),
              _: 1
            }),
            (_ctx.attachments)
              ? (_openBlock(), _createBlock(_component_AttachmentsBlock, {
                  key: 0,
                  attachments: _ctx.attachments,
                  selected: _ctx.selectedAttachments,
                  onAttachmentSelect: _ctx.attachmentSelect,
                  onAttachmentDeselect: _ctx.attachmentDeselect
                }, null, 8, ["attachments", "selected", "onAttachmentSelect", "onAttachmentDeselect"]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createVNode(_component_AddToCartSuccessModal, {
      opened: _ctx.showModal,
      email: _ctx.values.email,
      onClose: _ctx.closeModal
    }, null, 8, ["opened", "email", "onClose"])
  ]))
}