import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ProductCard = _resolveComponent("ProductCard")!

  return (_openBlock(), _createElementBlock("button", {
    class: "cursor-pointer relative",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.productClick && _ctx.productClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["card-icon", { 'rounded-tr-2': _ctx.isClicked }])
    }, [
      (_ctx.isClicked)
        ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            icon: "check",
            class: "w-full"
          }))
        : (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            icon: "plus",
            class: "w-3 h-3"
          }))
    ], 2),
    _createVNode(_component_ProductCard, _mergeProps(_ctx.$attrs, {
      product: _ctx.product,
      class: { 'product-card': _ctx.isClicked }
    }), null, 16, ["product", "class"])
  ]))
}