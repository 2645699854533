import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3f8ed34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "flex mb-3.5 lg:mb-0" }
const _hoisted_4 = { class: "w-1/2 lg:w-[12.5rem] mr-1.5" }
const _hoisted_5 = { class: "price-container" }
const _hoisted_6 = { class: "price-per-day" }
const _hoisted_7 = { class: "flex justify-end flex-wrap" }
const _hoisted_8 = {
  key: 2,
  class: "attachment-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseRadioButtonGroup = _resolveComponent("BaseRadioButtonGroup")!
  const _component_BaseDropdownInput = _resolveComponent("BaseDropdownInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_CalendarInputs = _resolveComponent("CalendarInputs")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_IncrementInput = _resolveComponent("IncrementInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseText, { class: "title" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('product_request_product_title')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.product.imageUrl)
        ? (_openBlock(), _createBlock(_component_ProductImage, {
            key: 0,
            src: _ctx.product.imageUrl,
            alt: "Product",
            class: "product-image"
          }, null, 8, ["src"]))
        : (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            icon: _ctx.getFallbackImage(_ctx.product),
            alt: "Product",
            class: "image-placeholder"
          }, null, 8, ["icon"])),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseText, {
          variant: "18-20",
          class: "font-bold"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.product.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseText, {
          variant: "18-20",
          class: "font-bold mb-2 lg:mb-3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.product.model), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseText, { class: "article-number" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(`${_ctx.t('product_request_art_nr')} ${_ctx.product.id}`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseRadioButtonGroup, {
          class: "radio-buttons",
          modelValue: _ctx.form.type,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.type) = $event)),
            _ctx.changeDeliveyType
          ],
          name: "type",
          options: _ctx.deliveryOptions
        }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]),
        (_ctx.isPickup)
          ? (_openBlock(), _createBlock(_component_InputGroup, {
              key: 0,
              label: _ctx.t('product_request_pickup_label'),
              class: "w-full mb-2",
              error: _ctx.form.deliveryAddressErr
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseDropdownInput, {
                  class: "w-full",
                  options: _ctx.availableBranches[_ctx.version],
                  name: "deliveryAddress",
                  modelValue: _ctx.form.deliveryAddress,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.deliveryAddress) = $event))
                }, null, 8, ["options", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "error"]))
          : (_openBlock(), _createBlock(_component_InputGroup, {
              key: 1,
              label: _ctx.t('product_request_delivery_label'),
              class: "w-full mb-2",
              error: _ctx.form.deliveryAddressErr
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseInput, {
                  class: "input",
                  name: "deliveryAddress",
                  modelValue: _ctx.form.deliveryAddress,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.deliveryAddress) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label", "error"])),
        _createVNode(_component_Calendar, {
          modelValue: _ctx.form.range,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.range) = $event)),
          onDaysChange: _ctx.updateDays
        }, {
          default: _withCtx(({ values, events }) => [
            _createVNode(_component_CalendarInputs, {
              values: values,
              events: events
            }, null, 8, ["values", "events"])
          ]),
          _: 1
        }, 8, ["modelValue", "onDaysChange"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_IncrementInput, {
              modelValue: _ctx.form.quantity,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.quantity) = $event)),
              name: "quantity",
              min: 1
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.isOpenEnded)
            ? (_openBlock(), _createBlock(_component_BaseText, {
                key: 0,
                variant: "18-20",
                class: "flex lg:mr-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`${_ctx.calculateDaysDifference(_ctx.range)} ${_ctx.t('product_request_each_day')}`), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_BaseText, {
            variant: "18-20",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.priceLabel), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_BaseText, {
            variant: "18-20",
            class: "font-bold"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_request_estimated_price')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseText, {
            variant: "18-20",
            class: "font-bold ml-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.sumLabel), 1)
            ]),
            _: 1
          })
        ])
      ]),
      (_ctx.product.is_attachment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.t('product_request_attachment_label')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}