
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import { useNumbers } from '@/shared/composables/useNumbers';
import { useProductFields } from '../composables/useProductFields';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import { DateRange, DateStringsRange } from '@/shared/interfaces/calendar';
import { useDropdownsOptions } from '@/shared/composables/useDropdownsOptions';
import { getPrice, getPriceWithIncurance } from '../utils/priceCalculation';
import { routes } from '@/shared/constants/routes';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import { formatDate, calculateDaysDifference } from '@/shared/utils/formatDate';
import Calendar from '@/shared/components/Calendar/Calendar.vue';
import CalendarInputs from '@/pages/ProductAddToCart/components/CalendarInputs.vue';
import { initialRange } from '@/shared/constants/initialRange';

const defaultDeliveryOption = 'pickup';

export default defineComponent({
  name: 'RequestProductForm',
  components: {
    Calendar,
    CalendarInputs,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
  },
  setup(props) {
    const { t } = useTranslations();
    const range = ref<DateStringsRange>(initialRange);
    const { getFallbackImage } = useFallbackImage();
    const { cPerDay, c } = useNumbers();
    const { deliveryOptions, availableBranches } = useDropdownsOptions();
    const { form } = useProductFields();
    const version = getConfig().appVersion;

    const changeDeliveyType = () => {
      form.value.deliveryAddress = '';
    };

    const isPickup = computed(() => form.value.type === defaultDeliveryOption);
    const price = computed(
      () => getPrice(range.value, props.product.pricing) * Number(form.value.quantity)
    );
    const isOpenEnded = computed(
      () => (form.value.range as DateRange).end === t('calendar_open_ended')
    );
    const priceLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${formatDate((form.value.range as DateRange).start)}`
        : cPerDay(
            getPriceWithIncurance(
              price.value,
              Number(props.product.insurance),
              Number(form.value.quantity)
            )
          )
    );
    const sumLabel = computed(() =>
      isOpenEnded.value
        ? `${t('calendar_from')} ${cPerDay(props.product.pricing.monthly)}`
        : c(form.value.sum as number)
    );

    const updateDays = (value: DateStringsRange) => {
      range.value = value;
    };

    watch(range, (newRange) => {
      form.value.price = getPrice(newRange, props.product.pricing);
    });

    watch([price, range], ([newPrice, newRange]) => {
      form.value.sum =
        getPriceWithIncurance(
          newPrice,
          Number(props.product.insurance),
          Number(form.value.quantity)
        ) * calculateDaysDifference(newRange);
    });

    return {
      t,
      deliveryOptions,
      availableBranches,
      getFallbackImage,
      isPickup,
      changeDeliveyType,
      range,
      price,
      cPerDay,
      c,
      routes,
      form,
      updateDays,
      priceLabel,
      sumLabel,
      isOpenEnded,
      version,
      calculateDaysDifference,
    };
  },
});
