import { object, string, number, array, date } from 'yup';
import { TranslationFun } from '@/shared/composables/useTranslations';

export const productValidationSchema = (t: TranslationFun) =>
  object({
    range: object({
      start: date().required(t('error_message_date')),
    }),
    quantity: number(),
    deliveryAddress: string().required(t('error_message_delivery_address')),
    type: string(),
    attachments: array().of(
      object({
        range: object({
          start: date().required(t('error_message_date')),
        }),
        id: number(),
        sum: number(),
      })
    ),
    sum: number(),
    price: number(),
  });
