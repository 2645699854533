import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductDetailsKey } from '../constants/injectables';
import { Product } from '@/shared/interfaces/product';
import { ProductDetails } from '@/shared/interfaces/productDetails';

interface IUseProductAttachments {
  products: Ref<Product[] | undefined>;
  error: Ref<unknown>;
  isLoading: Ref<boolean>;
  getAttachments: (product: ProductDetails) => Promise<void>;
}

export const useProductAttachments = (): IUseProductAttachments => {
  const service = injectStrict(ProductDetailsKey);
  const products = ref<Product[]>();
  const isLoading = ref<boolean>(false);
  const error = ref();

  const fetchAttachments = async (categoryId: number) => {
    isLoading.value = true;
    try {
      const res = await service.getProductAttachments(categoryId.toString());
      if (res.status === 200) {
        products.value = res.data;
      } else {
        error.value = res;
      }
    } catch (err) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  };

  const getAttachments = async (product: ProductDetails) => {
    const categoryId = product.attachment_categories[0]?.id;
    const isAttachment = product?.is_attachment;
    if (categoryId && !isAttachment) fetchAttachments(categoryId);
  };

  return {
    getAttachments,
    products,
    error,
    isLoading,
  };
};
